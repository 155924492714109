<template>
  <section class="Partners">
    <div class="Container">
      <div class="Partners-TitleBox">
        <h3 class="Title-h3 Partners-Title">
          Our Partners
        </h3>
        <div class="Partners-Subtitle">
          Through communication with our clients we deeply understand the reasons behind thier business, the goals that
          they aspire to and the impact this will have on their customers and business.
        </div>
      </div>
      <div class="Partners-List">
        <div class="Partners-Item"
             v-for="(info, index) in listInfo"
             :key="index"
        >
          <img src="../assets/images/quote.svg" alt="quote" class="Partners-Quote">
          <p class="Text Partners-Text" v-html="info.text"></p>
          <div class="Partners-Box">
            <img :src="require(`@/assets/images/${info.logo}`)" alt="logo" class="Partners-Logo">
            <div class="Partners-Company">
              <div class="Partners-Name">{{ info.name }}</div>
              <div class="Partners-Position">{{ info.position }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Partners",
  data() {
    return {
      listInfo: [
        {
          text: "We enjoyed working with the ADPRO MARKETING LIMITED team and hope to have other collaborative projects. They showed a keen interest in our needs, were patient, and were ready to help with all our services.",
          logo: "pennymac.png",
          name: "SOPHIA MAGALLAN",
          position: ""
        },
        {
          text: "Workeing with ADPRO MARKETING LIMITED was a great pleasure. Everything proceeded in a smooth and fast manner. I would rate our experience in collaboration as a 10 out of 10.",
          logo: "zesty.png",
          name: "sharon grainger",
          position: ""
        },
        {
          text: "The customer service was amazing! There was no stress. Calls weren’t neccesary as they updated every step of the process.",
          logo: "collegewise.png",
          name: "Vanessa Daves-Poarch",
          position: "Marketing Associate"
        },
        {
          text: "I’d like to thank the ADPRO MARKETING LIMITED team who’ve helped make our project a reality – the timeline was tight and the expectations high.",
          logo: "gett.png",
          name: "angelica garcia",
          position: ""
        },
        {
          text: "Everything progressed smoothly and quickly. Communication was clear. We even closed several days prior to the originally anticipated date. It was a great experience from start to finish.",
          logo: "bpisport.png",
          name: "clarence robinson",
          position: "CEO"
        },
        {
          text: "Everyone I dealt with in ADPRO MARKETING LIMITED were super nice, friendly, and helpful. They were able to answer all my questions thoroughly and patiently. The closing was ahead of schedule which helped saved money on our project. Perfect job!",
          logo: "regus.png",
          name: "WALTER PRIEST ",
          position: ""
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Partners {
  padding: 150px 0 100px;

  @media (min-width: $screen-l) {
    padding: 200px 0 150px;
  }

  &-TitleBox {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-l) {
      flex-direction: row;
      margin-bottom: 68px;
      padding-left: 80px;
    }
  }

  &-Title {
    margin-bottom: 40px;
    white-space: nowrap;
    color: var(--color-text-main2);
    @media (min-width: $screen-l) {
      margin-right: 33px;
      margin-bottom: 0;
    }
  }

  &-Subtitle {
    margin-bottom: 60px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;

    @media (min-width: $screen-l) {
      margin-bottom: 0;
      padding-right: 30px;
      padding-left: 13px;
      position: relative;
      text-align: left;
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 32px;
        background-color: var(--color-text-yellow-dark);
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &-List {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 30px 0;
    justify-content: center;

    @media (min-width: $screen-s) {
      grid-template-columns: 540px;
    }

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(2, 540px);
      grid-gap: 30px;
    }
  }

  &-Item {
    padding: 45px 36px 30px;
    box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  &-Quote {
    margin-bottom: 14px;
  }

  &-Text {
    margin-bottom: 18px;
  }

  &-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $screen-s) {
      flex-direction: row;
    }
  }

  &-Logo {
    margin-bottom: 30px;
    @media (min-width: $screen-s) {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &-Company {
    padding-left: 23px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 23px;
      background-color: rgba(178, 179, 190, .3);
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
  }

  &-Name {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    text-transform: capitalize;
  }

  &-Position {
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-text-main2);
  }
}
</style>