<template>
  <section class="PrivacyPolicy">
    <div class="Container">
      <h3 class="Title-h3 PrivacyPolicy-Title">
        Privacy Policy for ADPRO MARKETING LIMITED
      </h3>
      <p class="Text PrivacyPolicy-Text">
        <strong>ADPRO MARKETING LIMITED</strong>, registration number 13611811, a company organized andexisting under the
        laws of
        United Kingdom, whose registered office is at 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ.
      </p>
      <ul class="PrivacyPolicy-List">
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Definitions</h4>
          <p class="Text PrivacyPolicy-ItemText">
            <strong>«We»</strong> or <strong>«us»</strong> or <strong>«Company»</strong> means
            <strong>ADPRO MARKETING LIMITED</strong>.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            In our Company accessible at <strong>adpromarketingltd.com</strong>, one of the key priorities is the privacy
            of our visitors. This Privacy Policy document contains types of information that is collected and recorded
            by us and how we use it.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            If you have additional questions or require more information about our Privacy Policy, do not
            hesitate to contact us through email at <strong>info@adpromarketingltd.com</strong>
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            This privacy policy applies only to our online activities and is valid for visitors to our
            website with regards to the information that they shared and/or collect in our Company. This policy is not
            applicable to any information collected offline or via channels other than this website
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Consent</h4>
          <p class="Text PrivacyPolicy-ItemText">
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Information we collect</h4>
          <p class="Text PrivacyPolicy-ItemText">
            The personal information that you are asked to provide, and the reasons why you are asked to provide it,
            will be made clear to you at the point we ask you to provide your personal information
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            If you contact us directly, we may receive additional information about you such as your name, email
            address, phone number, the contents of the message and/or attachments you may send us, and any other
            information you may choose to provide.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            When you register for an Account, we may ask for your contact information, including items such as name,
            company name, address, email address, and telephone number.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">How we use your information</h4>
          <p class="Text PrivacyPolicy-ItemText">
            We use the information we collect in various ways, including to:
          </p>
          <ul class="PrivacyPolicy-Sublist">
            <li class="PrivacyPolicy-Subitem">
              Provide, operate, and maintain our website
            </li>
            <li class="PrivacyPolicy-Subitem">
              Improve, personalize, and expand our website
            </li>
            <li class="PrivacyPolicy-Subitem">
              Understand and analyze how you use our website
            </li>
            <li class="PrivacyPolicy-Subitem">
              Develop new products, services, features, and functionality
            </li>
            <li class="PrivacyPolicy-Subitem">
              Communicate with you, either directly or through one of our partners,
              including for customer service, to provide you with updates and other information relating to the website,
              and for marketing and promotional purposes
            </li>
            <li class="PrivacyPolicy-Subitem">
              Send you emails
            </li>
            <li class="PrivacyPolicy-Subitem">
              Find and prevent fraud
            </li>
          </ul>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Log Files</h4>
          <p class="Text PrivacyPolicy-ItemText">
            We follow a standard procedure of using log files. These files log visitors when they visit websites. All
            hosting companies do this and a part of hosting services' analytics. The information collected by log files
            include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time
            stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that
            is personally identifiable. The purpose of the information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic information.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Cookies and Web Beacons</h4>
          <p class="Text PrivacyPolicy-ItemText">
            Like any other website, we use ‘cookies'. These cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed or visited. The information is used to
            optimize the users' experience by customizing our web page content based on visitors' browser type and/or
            other information.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">GDPR Privacy Policy (Data Protection Rights)</h4>
          <p class="Text PrivacyPolicy-ItemText">
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled
            to the following:
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to access – You have the right to request copies of your personal data. We may charge you a small
            fee for this service.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to rectification – You have the right to request that we correct any information you believe is
            inaccurate. You also have the right to request that we complete the information you believe is incomplete.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to erasure – You have the right to request that we erase your personal data, under certain
            conditions.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to restrict processing – You have the right to request that we restrict the processing of your
            personal data, under certain conditions.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to object to processing – You have the right to object to our processing of your personal data,
            under certain conditions.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            The right to data portability – You have the right to request that we transfer the data that we have
            collected to another organization, or directly to you, under certain conditions.
          </p>
          <p class="Text PrivacyPolicy-ItemText">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these
            rights, please contact us.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Disclosure</h4>
          <p class="Text PrivacyPolicy-ItemText">
            Our Company shall not disclose the visitor’s personal data to any third party, except where (i) it has been
            agreed between the us and you, (ii) it is necessary within the scope of our contract, (iii) it is necessary
            in order to fulfil a statutory obligation, comply with a decision of a public authority or court of law or
            (iv) in cases where the Company engages an external service provider or business partner who perform
            services on behalf of the Company. We shall not sell the visitor’s personal data.
          </p>
        </li>
        <li class="PrivacyPolicy-Item">
          <h4 class="PrivacyPolicy-ItemTitle">Governing Law and Dispute Resolution</h4>
          <p class="Text PrivacyPolicy-ItemText">
            The Privacy Policy shall be construed in accordance with and governed by the Laws England and shall be
            subject to the exclusive jurisdiction of the English Courts.
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped lang="scss">
.PrivacyPolicy {
  padding: 200px 0 150px;

  &-Title {
    margin-bottom: 30px;
    text-align: center;
    color: var(--color-text-main2);

    @media (min-width: $screen-m) {
      padding-left: 80px;
      text-align: left;
    }
  }

  &-Text {
    margin-bottom: 60px;
  }

  &-List {
    margin-left: 40px;
  }

  &-Item {
    margin-bottom: 40px;
    list-style: decimal;

    &:last-child {
      margin-bottom: 0;
    }

    &::marker {
      font-weight: 700;
      font-size: 22px;
      color: var(--color-text-main2);
    }
  }

  &-ItemTitle {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 22px;
    color: var(--color-text-main2);
  }

  &-ItemText {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-Sublist {
    margin-left: 40px;
  }

  &-Subitem {
    margin-bottom: 5px;
    list-style: disc;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>